
export const USER_API = {
    LOGIN: 'auth/login',
    CHANGE_PASSWORD: 'auth/changePassword',
    HOME: 'home',
    IN_PLAY: 'inPlay',
    MULTI_LIST: 'multi',
    PIN: 'multi/pin',
    PLACE_BET: 'sports/placeBet',
    BET_LIST: 'sports/betList',
    SEARCH: 'sports/search',
    GET_CHANNEL_ID: 'sports/getChannelId',
    GET_STACK: 'getStack',
    UPDATE_STACK: 'updateStack',
    GET_BLOCK: 'sports/getBlockMatch',
    GET_PROFILE: 'auth/getProfile',
    MARKET_LIST: 'home/marketList',
    CASINO_LOGIN: 'casino/login',
    ADD_DEPOSIT: 'onlinePayment/addDeposit',
    ADD_WITHDRAW: 'onlinePayment/addWithdrawal',
    GET_METHOD: 'onlinePayment/getMethod',
    GET_WITHDRAWAL_REQUEST: 'onlinePayment/getWithdrawalRequest',
    GET_DEPOSIT_REQUEST: 'onlinePayment/getDepositRequest',
}

export const ADMIN_API = {
    LOGIN: 'login',
    GET_ROLE: 'getRole',
    DOWN_LINE_LIST: 'downLineList',
    PLAYER_CREATE: 'downLineList/player/create',
    PLAYER_GET_PROFILE: 'downLineList/player/getProfile',
    PLAYER_BET_HISTORY: 'downLineList/player/betHistory',
    PLAYER_CASINO_BET_HISTORY: 'downLineList/player/casinoHistory',

    AGENT_CREATE: 'downLineList/agent/create',
    AGENT_UPDATE_INFO: 'downLineList/agent/updateInfo',
    PLAYER_UPDATE_INFO: 'downLineList/player/updateInfo',
    AGENT_GET_LIST: 'downLineList/agent/getList',
    BET_HISTORY: 'downLineList/player/betHistory',
    REPORT: {
        DOWN_LINE: 'report/downline',
        ACCOUNT_STATEMENT: 'report/accountStatment',
        STATEMENT_BET_VIEW: 'myAccount/statementBetView'
    },
    MY_ACCOUNT: {
        GET_PROFILE: 'myAccount/getProfile',
        GET_ACTIVITIES: 'myAccount/getActivities',
        GET_STATEMENTS: 'myAccount/getStatements',
    },
    BET_LIST: {
        LIST_LIVE: 'betList/liveList',
        LIST: 'betList/list'
    },
    RISK: 'risk',
    BANKING: {
        GET_BALANCE: 'banking/master/getBalance',
        ADD_BALANCE: 'banking/master/addBalance',
        GET_LIST: 'banking/getList',
        UPDATE: 'banking/update'
    },
    SPORTS: {
        LIST: 'sports/getList'
    },
    SETTING: {
        BANNER: {
            GET_LIST: 'setting/banner',
            GET_ONE: 'setting/banner/getOne',
            CREATE: 'setting/banner/create',
            UPDATE: 'setting/banner/update',
        },
        DASHBOARD: {
            GET_LIST: 'setting/dashboard',
            GET_ONE: 'setting/dashboard/getOne',
            CREATE: 'setting/dashboard/create',
            UPDATE: 'setting/dashboard/update',
        },
        MANAGE_CASINO: {
            GET_LIST: 'setting/manageCasino',
            CREATE: 'setting/manageCasino/create'
        },
        PREMIUM_HISTORY: {
            GET_LIST: 'setting/premiumHistory',
            LIST_OF_BET: 'setting/premiumHistory/listOfBet',
            ROLL_BACK_WINNER: 'setting/premiumHistory/rollBackWinner',
        },
        FANCY_HISTORY: {
            GET_LIST: 'setting/fancyHistory',
            LIST_OF_BET: 'setting/fancyHistory/listOfBet',
            ROLL_BACK_WINNER: 'setting/fancyHistory/rollBackWinner'
        },
        MANAGE_PREMIUM: {
            GET_LIST: 'setting/managePremium',
            LIST_OF_BET: 'setting/managePremium/listOfBet',
            DECLARE_WINNER: 'setting/managePremium/declareWinner',
        },
        MANAGE_FANCY: {
            GET_LIST: 'setting/manageFancy',
            LIST_OF_BET: 'setting/manageFancy/listOfBet',
            DECLARE_WINNER: 'setting/manageFancy/declareWinner'
        },
        MATCH_HISTORY: {
            GET_LIST: 'setting/matchHistory'
        },
        SPORT_MARKET: {
            GET_LIST: 'sports/sportMarket',
            DECLARE_WINNER: 'sports/declareWinner',
            UPDATE: 'sports/updare',
        },
        WEBSITE: {
            CREATE: 'setting/website',
            UPDATE: 'setting/website/update',
            GET_SITE: 'setting/website/getSite',
            LIST: 'setting/website/list',
            GET_DOMAIN: 'setting/website/getDomain',
        },
        FILE_UPLOAD: 'setting/fileUpload'
    }
}
// const percentage = { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }
export const COLOR_OPTION = [
    { backgroundColor: "#FFCC2E", darbackgroundColor: '#000', color: "#222", backgroundColorSecond: "#FFB80C", darbackgroundColorSecond: '#000', label: 'Yellow', value: 'yellow', headerTextColor: "#ffcc2f", percentage : { backgroundColor : { start : "4%", end : "42%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#e83523", loginFirstbackgroundColor: "#f72424", loginbackgroundColorSecond: "#bb1c00" },
    { backgroundColor: "#67f0c2", darbackgroundColor: '#00724b ', color: "#222", backgroundColorSecond: "#67f0c2", darbackgroundColorSecond: '#000 ', label: 'Onsen', value: 'onsen', headerTextColor: "#67f0c2", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#67f0c2", loginFirstbackgroundColor: "#67f0c2", loginbackgroundColorSecond: "#67f0c2" },
    { backgroundColor: "#291e39", darbackgroundColor: '#2a0066', color: "#FFF", backgroundColorSecond: "#291e39", darbackgroundColorSecond: '#000', label: 'Purple', value: 'purple', headerTextColor: "#291e39", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#291e39", loginFirstbackgroundColor: "#291e39", loginbackgroundColorSecond: "#291e39" },
    { backgroundColor: "#e80000", darbackgroundColor: '#680202', color: "#FFF", backgroundColorSecond: "#a70000", darbackgroundColorSecond: '#000',  label: 'Red', value: 'red', headerTextColor: "#DE352C", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#e80000", loginFirstbackgroundColor: "#e80000", loginbackgroundColorSecond: "#a70000" },
    { backgroundColor: "#0047ab", darbackgroundColor: '#0e2749', color: "#000", backgroundColorSecond: "#0047ab", darbackgroundColorSecond: '#0e2749',  label: 'Blue', value: 'blue', headerTextColor: "#5D6699", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#0047ab", loginFirstbackgroundColor: "#0047ab", loginbackgroundColorSecond: "#0047ab" },
    { backgroundColor: "#000000", darbackgroundColor: '#000000', color: "#FFF", backgroundColorSecond: "#000000", darbackgroundColorSecond: '#000',  label: 'Black', value: 'black', headerTextColor: "#000000", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#000000", loginFirstbackgroundColor: "#000000", loginbackgroundColorSecond: "#000000" },
    { backgroundColor: "#FF3377", darbackgroundColor: '#600020 ', color: "#FFF", backgroundColorSecond: "#FF3377", darbackgroundColorSecond: '#000 ',  label: 'Pink', value: 'pink', headerTextColor: "#FF3377", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#FF3377", loginFirstbackgroundColor: "#FF3377", loginbackgroundColorSecond: "#FF3377" },
    { backgroundColor: "#FFFEBB", darbackgroundColor: '#5d5c00', color: "#000", backgroundColorSecond: "#FFFEBB", darbackgroundColorSecond: '#000',  label: 'Light Yellow', value: 'light-yellow', headerTextColor: "#FFFEBB", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#FFFEBB", loginFirstbackgroundColor: "#FFFEBB", loginbackgroundColorSecond: "#FFFEBB" },
    { backgroundColor: "#FF8C00", darbackgroundColor: '#4d2a00', color: "#FFF", backgroundColorSecond: "#FF8C00", darbackgroundColorSecond: '#000',  label: 'Dark Orange', value: 'dark-orange', headerTextColor: "#FF8C00", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#FF8C00", loginFirstbackgroundColor: "#FF8C00", loginbackgroundColorSecond: "#FF8C00" },
    { backgroundColor: "#FFD700", darbackgroundColor: '#5c4d00', color: "#000", backgroundColorSecond: "#FFD700", darbackgroundColorSecond: '#000',  label: 'Gold', value: 'gold', headerTextColor: "#FFD700", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#FFD700", loginFirstbackgroundColor: "#FFD700", loginbackgroundColorSecond: "#FFD700" },
    { backgroundColor: "#0CFE09", darbackgroundColor: '#015100', color: "#313131", backgroundColorSecond: "#0CFE09", darbackgroundColorSecond: '#000',  label: 'Radioactive Green', value: 'radioactive-green', headerTextColor: "#0CFE09", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#0CFE09", loginFirstbackgroundColor: "#0CFE09", loginbackgroundColorSecond: "#0CFE09" },
    { backgroundColor: "#708090", darbackgroundColor: '#002a55', color: "#000", backgroundColorSecond: "#708090", darbackgroundColorSecond: '#000',  label: 'Slategrey', value: 'slategrey', headerTextColor: "#708090", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#708090", loginFirstbackgroundColor: "#708090", loginbackgroundColorSecond: "#708090" },
    { backgroundColor: "#0D4F8B", darbackgroundColor: '#00203d', color: "#FFF", backgroundColorSecond: "#0D4F8B", darbackgroundColorSecond: '#000',  label: 'Indigo Dye', value: 'indigo-dye', headerTextColor: "#0D4F8B", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#0D4F8B", loginFirstbackgroundColor: "#0D4F8B", loginbackgroundColorSecond: "#0D4F8B" },
    { backgroundColor: "#428139", darbackgroundColor: '#094800', color: "#FFF", backgroundColorSecond: "#428139", darbackgroundColorSecond: '#000',  label: 'Green', value: 'green', headerTextColor: "#428139", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#428139", loginFirstbackgroundColor: "#428139", loginbackgroundColorSecond: "#428139" },
    { backgroundColor: "#00A884", darbackgroundColor: '#013329', color: "#000", backgroundColorSecond: "#00A884", darbackgroundColorSecond: '#000',  label: 'Persian Green', value: 'persian-green', headerTextColor: "#00A884", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#00A884", loginFirstbackgroundColor: "#00A884", loginbackgroundColorSecond: "#00A884" },
    { backgroundColor: "#51e2f5", darbackgroundColor: '#003c44', color: "#000", backgroundColorSecond: "#51e2f5", darbackgroundColorSecond: '#000',  label: 'Electric Blue', value: 'electric-blue', headerTextColor: "#51e2f5", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#51e2f5", loginFirstbackgroundColor: "#51e2f5", loginbackgroundColorSecond: "#51e2f5" },
    // { backgroundColor: "#0a58ca", darbackgroundColor: ' ', color: "#FFF", label: 'Dark Royal Blue', value: 'dark-royal-blue', percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} } },
    { backgroundColor: "#0047ab", darbackgroundColor: '#0e2749', color: "#FFF", backgroundColorSecond: "#0047ab", darbackgroundColorSecond: '#0e2749', label: 'Dark Royal Blue', value: 'dark-royal-blue', headerTextColor: "#6ac2ff", percentage : { backgroundColor : { start : "0%", end : "100%", }, darbackgroundColor : { start : "0%", end : "100%",} }, loginBackgroundColor: "#0047ab", loginFirstbackgroundColor: "#0047ab", loginbackgroundColorSecond: "#0047ab" },
]